import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

// Import Images
import lingBg2 from "../../images/background/line-bg2.png"
import animate1 from "../../images/shap/trangle-orange.png"
import animate2 from "../../images/shap/square-dots-orange.png"
import animateRotate from "../../images/shap/line-circle-blue.png"
import animateWave from "../../images/shap/wave-blue.png"
import testPic1 from "../../images/testimonials/pic1.jpg"
import testPic2 from "../../images/testimonials/pic2.jpg"
import testPic3 from "../../images/testimonials/pic3.jpg"
import testPic4 from "../../images/testimonials/pic4.jpg"
import testPic5 from "../../images/testimonials/pic5.jpg"
import blogGridPic1 from "../../images/blog/grid/pic1.jpg"
import blogGridPic2 from "../../images/blog/grid/pic2.jpg"
import blogGridPic3 from "../../images/blog/grid/pic3.jpg"
import blogGridPic4 from "../../images/blog/grid/pic4.jpg"
import blogGridPic5 from "../../images/blog/grid/pic5.jpg"
import manipal from "../../images/shap/manipal.jpg"
import gas from "../../images/blog/grid/gas.jpg"
import liver from "../../images/blog/grid/liver.jpg"
import colon from "../../images/blog/grid/colon.jpg"
import thyroid from "../../images/blog/grid/thyroid.jpg"
import varicose from "../../images/blog/grid/varicose.jpg"
import foot from "../../images/blog/grid/foot.jpg"

// Team Content
const content = [
	{ 
		thumb: blogGridPic1,
		authorPic: manipal,
		author: "Dr. Manipal Puvvala",
		title: "In this hospital there are special surgeon",		
		date: "21 May 2022",
	},
	{ 
		thumb: blogGridPic2,
		authorPic: manipal,
		author: "Dr. Manipal Puvvala",
		title: "Can you get a diflucan prescription online?",		
		date: "20 June 2022",
	},
	{ 
		thumb: blogGridPic3,
		authorPic: manipal,
		author: "Dr. Manipal Puvvala",
		title: "Why Is Skin Surgeon Considered Underrated",		
		date: "19 July 2022",
	},
	{ 
		thumb: blogGridPic4,
		authorPic: manipal,
		author: "Dr. Manipal Puvvala",
		title: "Dental Care for Women is very important",		
		date: "18 August 2022",
	},
	{ 
		thumb: blogGridPic5,
		authorPic: manipal,
		author: "Dr. Manipal Puvvala",
		title: "Health Will Be A Thing Of The Past And Here's Why",		
		date: "10 September 2022",
	},
]

class LatestNewsSection extends Component{
	render(){
			
		const settings = {
			dots: false,
			infinite: true,
			speed: 1000,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1191,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
		
		return(
			<>
				
				<section className="section-area section-sp1 blog-area" style={{backgroundImage: "url("+lingBg2+")", backgroundPosition: "center", backgroundSize: "cover",}}>
					<div className="container">
						<div className="heading-bx text-center">
							<h6 className="title-ext text-secondary">Latest News</h6>
							<h2 className="title">Our Latest News</h2>
						</div>
						<Slider {...settings} className="tt-slider blog-slide slider-sp0 slick-arrow-none">
							
								<div className="slider-item">
									<div className="blog-card">
										<div className="post-media">
											<Link to="/blog-gastritis"><img src={gas} alt=""/></Link>
										</div>
										<div className="post-info">
											<ul className="post-meta">
												<li className="author"><Link to="/blog-gastritis"><img src={manipal} alt=""/>Dr.Manipal Puvvala</Link></li>
												<li className="date"><i className="far fa-calendar-alt"></i>21 May 2022</li>
											</ul>
											<h5 className="post-title"><Link to="/blog-gastritis">SYMPTOMS AND CAUSES OF GASTRITIS</Link></h5>		
											<Link to="/blog-gastritis" className="btn btn-outline-primary btn-sm">Read More <i className="btn-icon-bx fas fa-chevron-right"></i></Link>
										</div>
									</div>	
								</div>
								<div className="slider-item">
									<div className="blog-card">
										<div className="post-media">
											<Link to="/blog-liver"><img src={liver} alt=""/></Link>
										</div>
										<div className="post-info">
											<ul className="post-meta">
												<li className="author"><Link to="/blog-liver"><img src={manipal} alt=""/>Dr.Manipal Puvvala</Link></li>
												<li className="date"><i className="far fa-calendar-alt"></i>20 June 2022</li>
											</ul>
											<h5 className="post-title"><Link to="/blog-liver">Liver Diseases: What You Should Know</Link></h5>		
											<Link to="/blog-liver" className="btn btn-outline-primary btn-sm">Read More <i className="btn-icon-bx fas fa-chevron-right"></i></Link>
										</div>
									</div>	
								</div>
								<div className="slider-item">
									<div className="blog-card">
										<div className="post-media">
											<Link to="/blog-colon"><img src={colon} alt=""/></Link>
										</div>
										<div className="post-info">
											<ul className="post-meta">
												<li className="author"><Link to="/blog-colon"><img src={manipal} alt=""/>Dr.Manipal Puvvala</Link></li>
												<li className="date"><i className="far fa-calendar-alt"></i>19 July 2022</li>
											</ul>
											<h5 className="post-title"><Link to="/blog-colon">Rectal and Colon Diseases and Conditions</Link></h5>		
											<Link to="/blog-colon" className="btn btn-outline-primary btn-sm">Read More <i className="btn-icon-bx fas fa-chevron-right"></i></Link>
										</div>
									</div>	
								</div>
								<div className="slider-item">
									<div className="blog-card">
										<div className="post-media">
											<Link to="/blog-thyroid"><img src={thyroid} alt=""/></Link>
										</div>
										<div className="post-info">
											<ul className="post-meta">
												<li className="author"><Link to="/blog-thyroid"><img src={manipal} alt=""/>Dr.Manipal Puvvala</Link></li>
												<li className="date"><i className="far fa-calendar-alt"></i>18 Aug 2022</li>
											</ul>
											<h5 className="post-title"><Link to="/blog-thyroid">WHAT CAUSES THYROID DISEASE?</Link></h5>		
											<Link to="/blog-thyroid" className="btn btn-outline-primary btn-sm">Read More <i className="btn-icon-bx fas fa-chevron-right"></i></Link>
										</div>
									</div>	
								</div>
								<div className="slider-item">
									<div className="blog-card">
										<div className="post-media">
											<Link to="/blog-diabetic"><img src={foot} alt=""/></Link>
										</div>
										<div className="post-info">
											<ul className="post-meta">
												<li className="author"><Link to="/blog-diabetic"><img src={manipal} alt=""/>Dr.Manipal Puvvala</Link></li>
												<li className="date"><i className="far fa-calendar-alt"></i>10 Sep 2022</li>
											</ul>
											<h5 className="post-title"><Link to="/blog-diabetic">DIABETIC FOOT PROBLEMS</Link></h5>		
											<Link to="/blog-diabetic" className="btn btn-outline-primary btn-sm">Read More <i className="btn-icon-bx fas fa-chevron-right"></i></Link>
										</div>
									</div>	
								</div>
								<div className="slider-item">
									<div className="blog-card">
										<div className="post-media">
											<Link to="/blog-varicose"><img src={varicose} alt=""/></Link>
										</div>
										<div className="post-info">
											<ul className="post-meta">
												<li className="author"><Link to="/blog-details"><img src={manipal} alt=""/>Dr.Manipal Puvvala</Link></li>
												<li className="date"><i className="far fa-calendar-alt"></i>11 Sep 2022</li>
											</ul>
											<h5 className="post-title"><Link to="/blog-varicose">HOW TO TREAT, WHAT CAUSES VARICOSE VEINS?</Link></h5>		
											<Link to="/blog-varicose" className="btn btn-outline-primary btn-sm">Read More <i className="btn-icon-bx fas fa-chevron-right"></i></Link>
										</div>
									</div>	
								</div>				
						</Slider>
					</div>
					<img className="pt-img1 animate1" src={animate1} alt=""/>
					<img className="pt-img2 animate2" src={animate2} alt=""/>
					<img className="pt-img3 animate-rotate" src={animateRotate} alt=""/>
					<img className="pt-img4 animate-wave" src={animateWave} alt=""/>
				</section>
				
			</>
		);
	}
}

export default LatestNewsSection;