import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// Layout
import Header from "../layout/header2";
import Footer from "../layout/footer";

// Elements
import AuthorProfile from "../elements/author-profile";
import CommentList from "../elements/comment-list";
import CommentRespond from "../elements/comment-respond";
import WidgetTag from "../elements/widget-tag";
import WidgetSearch from "../elements/widget-search";
import WidgetGallery from "../elements/widget-gallery";
import WidgetRecentPosts from "../elements/widget-recent-posts";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import blogDefaultPic1 from "../../images/blog/default/pic1.jpg";
import testPic3 from "../../images/testimonials/pic3.jpg";
import galleryPic2 from "../../images/gallery/pic2.jpg";
import galleryPic5 from "../../images/gallery/pic5.jpg";
import manipal from "../../images/shap/manipal.jpg";
import gas from "../../images/blog/grid/gas.jpg";
import footbanner from "../../images/blog/default/footbanner.jpg";


class BlogDetails extends Component{
	
	render(){
		return (
			<>
				
				<Header />
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>DIABETIC FOOT PROBLEMS</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Blog Details</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1 bg-white">
						<div className="container">
							<div className="row">
								<div className="col-md-12 col-lg-7 col-xl-8 mb-30 mb-md-50">
									<div className="blog-card blog-single">
										<div className="post-media">
											<img src={footbanner} alt=""/>
										</div>
										<div className="info-bx">
											<ul className="post-meta">
												<li className="author"><Link to="/blog-details"><img src={manipal} alt=""/>Dr. Manipal Puvvala</Link></li>
												<li className="date"><i className="far fa-calendar-alt"></i> 10 Sep 2022</li>
											</ul>
											<div className="ttr-post-title">
												<h2 className="post-title">DIABETIC FOOT PROBLEMS</h2>
											</div>
											<div className="ttr-post-text">
												<p>How Can Diabetes Affect My Feet?</p>
												<blockquote className="wp-block-quote">
													<p>If you have diabetes, having too much glucose (aka sugar) in your blood for a long time can cause some serious complications, including foot problems.</p>
												</blockquote>
												<p>Diabetes can cause two problems that can affect your feet:</p>
                                                <p>• Diabetic neuropathy. Uncontrolled diabetes can damage your nerves. If you have damaged nerves in your legs and feet, you might not feel heat, cold, or pain there. This lack of feeling is called "sensory diabetic neuropathy." If you do not feel a cut or sore on your foot because of neuropathy, the cut could get worse and become infected. The muscles of your foot may not work properly because nerves to the muscles are damaged. This could cause your foot to not align properly and create too much pressure on one part of your foot.</p>
                                                <p>• Peripheral vascular disease. Diabetes also affects the flow of blood. Without good blood flow, it takes longer for a sore or cut to heal. Poor blood flow in the arms and legs is called "peripheral vascular disease." If you have an infection that will not heal because of poor blood flow, you are at risk for developing ulcers or gangrene (the death of tissue due to a lack of blood).</p>
                                                <p>What Are Some Common Foot Problems With Diabetes?</p>
                                                <p>Anyone can get the foot problems listed below. For people with diabetes, however, these common foot problems can lead to infection and serious complications, such as amputation.</p>
                                                <p>• Athlete's foot. Athlete's foot is a fungus that causes itching, redness, and cracking. Germs can enter through the cracks in your skin and cause an infection. Medicines that kill the fungus can treat athlete's foot. These medicines come in pills or creams.</p>
                                                <p>• Fungal infection of nails. Nails that are infected with a fungus may become discolored (yellowish-brown or opaque), thick, and brittle and may separate from the rest of your nail. In some cases, your nail may crumble. The dark, moist, and warm environment of shoes can help a fungus grow. An injury to your nail can also lead to a fungal infection. Fungal nail infections are hard to treat. Medications applied to the nail are available, but they only help a small number of fungal nail problems. You may need prescription medicine that you take by mouth. Your doctor might also remove the damaged nail.</p>
                                                <p>• Calluses. A callus is a buildup of hard skin, usually on the underside of the foot. Calluses are caused by an uneven distribution of weight. Calluses also can be caused by poorly fitting shoes or by a skin problem. </p>
                                                <p>It's normal to have some callus on your foot, so your doctor will decide if your callus is causing problems. If you have a callus, take care of it properly. After your bath or shower, use a pumice stone to gently remove the built-up tissue. Use cushioned pads and insoles in your shoes. Medications can soften calluses. DO NOT try to cut the callus or remove it with a sharp object.</p>
                                                <p>• Corns. A corn is a buildup of hard skin near a bony area of a toe or between toes. Corns may happen because of pressure from shoes that rub against your toes or cause friction between your toes. Be sure to take care of them properly. After your bath or shower, use a pumice stone to gently remove the built-up tissue. Do not use over-the-counter remedies to dissolve corns. DO NOT try to cut the corn or remove it with a sharp object.</p>
                                                <p>• Blisters. Blisters can form when your shoes rub the same spot on your foot. Wearing shoes that do not fit properly or wearing shoes without socks can cause blisters, which can become infected. When treating blisters, it's important not to "pop" them. The skin covering the blister helps protect it from infection. Use an antibacterial cream and clean, soft bandages to protect the skin and prevent infection.</p>
												<p>• Bunions. A bunion forms when your big toe bends toward your second toe. Often, the spot where your big toe joins your foot becomes red and callused. This area also may stick out and become hard. Bunions can form on one or both feet. They may run in the family, but most often they're caused by wearing high-heeled shoes with narrow toes. These shoes put pressure on your big toe, pushing it toward your second toe. Felt or foam padding may help protect the bunion from irritation. Your doctor can also use a device to separate the big and second toes. If the bunion causes severe pain or deformity, you might need surgery to realign your toes.</p>
												<p>• Dry skin. Dry skin can crack, which can allow germs to enter. Use moisturizing soaps and lotions to keep your skin moist and soft. Ask your doctor which ones to use.</p>
                                                <p>• Diabetic ulcers. A foot ulcer is a break in the skin or a deep sore. They can become infected. Foot ulcers can happen from minor scrapes, cuts that heal slowly, or from the rubbing of shoes that do not fit well. It's important to treat them as soon as you notice them. Ask your doctor for advice on how to best care for your ulcer. Up to 10% of people with diabetes will get foot ulcers.</p>
                                                <p>• Hammertoes. A hammertoe is a toe that is bent because of a weakened muscle. The weakened muscle makes the tendons in your toe shorter, causing your toe to curl under your foot. Hammertoes can run in families. They can also be caused by shoes that are too short. Hammertoes can cause problems with walking and can lead to blisters, calluses, and sores. Splints and corrective shoes can treat them. In severe cases, you may need surgery to straighten your toes.</p>
                                                <p>• Ingrown toenails. Ingrown toenails happen when the edges of the nail grow into your skin. They cause pressure and pain along the nail edges. The edge of the nail may cut into your skin, causing redness, swelling, pain, drainage, and infection. The most common cause of ingrown toenails is pressure from shoes. Other causes include improperly trimmed nails, crowding of the toes, and repeated trauma to the feet from activities such as running, walking, or doing aerobics. Keep your toenails properly trimmed to prevent ingrown toenails. If you have a persistent problem or if you have a nail infection, you may need a doctor's care. Surgery to remove part of the toenail and growth plate can treat severe problems from ingrown toenails.</p>
                                                <p>• Plantar warts. Plantar warts look like calluses on the ball of the foot or on the heel. They may appear to have small pinholes or tiny black spots in the center. The warts are usually painful and may grow singly or in clusters. Plantar warts are caused by a virus that infects the outer layer of skin on the soles of the feet. If you are not sure if you have a plantar wart or a callus, let your doctor decide. They have several ways to remove them.</p>
                                                <p>Signs of Diabetic Foot Problems</p>
                                                <p>If you have diabetes, contact your doctor if you have any of these problems:</p>
                                                <p>• Changes in skin color</p>
                                                <p>• Changes in skin temperature</p>
                                                <p>• Swelling in the foot or ankle</p>
                                                <p>• Pain in the legs</p>
                                                <p>• Open sores on the feet that are slow to heal or are draining</p>
                                                <p>• Ingrown toenails or toenails infected with fungus</p>
                                                <p>• Corns or calluses</p>
                                                <p>• Dry cracks in the skin, especially around the heel</p>
                                                <p>• Foot odor that is unusual or won't go away</p>

											</div>
											<div className="ttr-post-footer">
												<div className="post-tags">
													<strong>Tags:</strong>
                                                    <Link to="#">Diabetic Foot</Link>
													<Link to="#">Health</Link> 
													<Link to="#">Growth</Link> 
													<Link to="#">Life</Link> 
												</div>
												<div className="share-post ml-auto">
													<ul className="social-media mb-0">
														<li><strong>Share:</strong></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.facebook.com/"><i className="fab fa-facebook-f"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.linkedin.com/"><i className="fab fa-linkedin-in"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									
									<AuthorProfile />
									
									
								</div>
								<div className="col-md-12 col-lg-5 col-xl-4 mb-30">
									<aside className="side-bar sticky-top aside-bx">
										
										<WidgetSearch />
										
										<WidgetRecentPosts />
										
										
										<WidgetTag />
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default BlogDetails;