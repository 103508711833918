import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// Layout
import Header from "../layout/header2";
import Footer from "../layout/footer";

// Elements
import AuthorProfile from "../elements/author-profile";
import CommentList from "../elements/comment-list";
import CommentRespond from "../elements/comment-respond";
import WidgetTag from "../elements/widget-tag";
import WidgetSearch from "../elements/widget-search";
import WidgetGallery from "../elements/widget-gallery";
import WidgetRecentPosts from "../elements/widget-recent-posts";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import manipal from "../../images/shap/manipal.jpg";
import liverbanner from "../../images/blog/default/liverbanner.jpg";


class BlogDetails extends Component{
	
	render(){
		return (
			<>
				
				<Header />
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Liver Diseases: What You Should Know</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Blog Details</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1 bg-white">
						<div className="container">
							<div className="row">
								<div className="col-md-12 col-lg-7 col-xl-8 mb-30 mb-md-50">
									<div className="blog-card blog-single">
										<div className="post-media">
											<img src={liverbanner} alt=""/>
										</div>
										<div className="info-bx">
											<ul className="post-meta">
												<li className="author"><Link to="/blog-details"><img src={manipal} alt=""/>Dr. Manipal Puvvala</Link></li>
												<li className="date"><i className="far fa-calendar-alt"></i>20 June 2022</li>
											</ul>
											<div className="ttr-post-title">
												<h2 className="post-title">Liver Diseases: What You Should Know</h2>
											</div>
											<div className="ttr-post-text">
												<p>Your liver does a lot of things that keep you healthy. It turns nutrients into chemicals your body needs. It filters out poisons. It helps turn food into energy. So when your liver doesn’t work well, that can affect your whole body.</p>
												<blockquote className="wp-block-quote">
													<p>Different things can cause serious liver conditions. You’ll want to know about the top causes</p>
												</blockquote>
												<p>INFECTIONS</p>
                                                <p>Sometimes, the problem is that you have an infection that inflames your liver. Viral hepatitis is the most common cause, including:</p>
                                                <p>• Hepatitis A. Most people get it by eating or drinking something that’s tainted by fecal matter. You might not have any symptoms. It usually goes away by itself within 6 months without any long-term harm.</p>
                                                <p>• Hepatitis B. You get it from somebody else, such as through unprotected sex or taking drugs with shared needles. If it lasts longer than 6 months, it makes you more likely to get liver cancer or other diseases.</p>
                                                <p>• Hepatitis C comes from infected blood that gets into your blood. You might get it if you take drugs with shared needles or in connection with HIV. If you’re a health-care worker, you might get it from an infected needle that accidentally sticks you. Symptoms may not show up for many years. For reasons that aren’t quite clear, baby boomers are at risk for hepatitis C and should be tested for it.</p>
                                                <p>IMMUNE SYSTEM PROBLEMS</p>
                                                <p>Your immune system fights off invaders including bacteria and viruses. But it might go wrong and attack one or more parts of your body, such as your liver.</p>
                                                <p>• Autoimmune hepatitis inflames your liver. It can lead to other disorders and even liver failure. It strikes girls and women more often than boys or men.</p>
                                                <p>• Primary biliary cholangitis attacks tiny tubes in your liver called bile ducts. They carry bile, a chemical that helps you digest food. When the ducts are injured, the bile backs up inside your liver and scars it. Women come down with this more often than men.</p>
                                                <p>• Primary sclerosing cholangitis scars your bile ducts, and it can eventually block them. The bile builds up inside your liver, and that makes it harder for your liver to work. It may lead to liver cancer, and you might someday need a liver transplant. Men are more likely than women to get it.</p>
                                                <p>CANCER AND TUMORS</p>
												<p>If cancer shows up in your liver, that’s most likely because it has spread from another part of your body, like your lungs, colon, or breasts. But a few cancers can start in the liver.</p>
												<p>• Liver cancer affects women more often than men, and African-Americans more often than whites. Your doctor might call it hepatocellular carcinoma. It’s more likely if you have hepatitis or drink too much.</p>
                                                <p>• Bile duct cancer strikes the tubes that run from your liver to your small intestine to carry bile, a fluid that helps you digest food. This kind of cancer mainly affects people over age 50, but it’s uncommon.</p>
                                                <p>• Liver cell adenoma is a tumor that doesn’t have cancer. It’s uncommon, but women who take birth control pills for a long time are more prone than other people to develop it. There’s a small chance the tumor could eventually turn into cancer.</p>
                                                <p>CONDITIONS YOU INHERIT</p>
                                                <p>Some inherited liver disorders only happen if they run in your family.</p>
                                                <p>• Hemochromatosis makes your body store up too much of the iron from your food. The extra iron builds up in your liver, heart, or other organs. It can lead to life-threatening conditions such as liver diseases, heart disease, or diabetes.</p>
                                                <p>• Hyperoxaluria hits when your urine has too much of a chemical called oxalate. In this condition, your liver makes too little much oxalate due to a genetic mutation. This can cause kidney stones and kidney failure. If your kidneys do fail, that can give you oxalosis, where the oxalate collects in other organs and causes more trouble.</p>
                                                <p>• Wilson's disease makes copper build up in your liver and other organs. Its first symptoms usually show up when you’re between the ages of 6 and 35, most often in your teens. It not only affects your liver, but it can cause nerve and psychiatric problems.</p>
												<p>• Alpha-1 antitrypsin deficiency involves a chemical that helps your lungs resist infections. Your liver makes it. But when your liver gets the recipe wrong, the faulty chemical can build up and cause liver disease.</p>
											</div>
											<div className="ttr-post-footer">
												<div className="post-tags">
													<strong>Tags:</strong>
                                                    <Link to="#">Liver Disorders</Link>
													<Link to="#">Health</Link> 
													<Link to="#">Growth</Link> 
													<Link to="#">Life</Link> 
												</div>
												<div className="share-post ml-auto">
													<ul className="social-media mb-0">
														<li><strong>Share:</strong></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.facebook.com/"><i className="fab fa-facebook-f"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.linkedin.com/"><i className="fab fa-linkedin-in"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									
									<AuthorProfile />
									
									
								</div>
								<div className="col-md-12 col-lg-5 col-xl-4 mb-30">
									<aside className="side-bar sticky-top aside-bx">
										
										<WidgetSearch />
										
										<WidgetRecentPosts />
										
										
										<WidgetTag />
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default BlogDetails;