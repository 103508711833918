import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// Layout
import Header from "../layout/header2";
import Footer from "../layout/footer";

// Elements
import AuthorProfile from "../elements/author-profile";
import CommentList from "../elements/comment-list";
import CommentRespond from "../elements/comment-respond";
import WidgetTag from "../elements/widget-tag";
import WidgetSearch from "../elements/widget-search";
import WidgetGallery from "../elements/widget-gallery";
import WidgetRecentPosts from "../elements/widget-recent-posts";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import blogDefaultPic1 from "../../images/blog/default/pic1.jpg";
import testPic3 from "../../images/testimonials/pic3.jpg";
import galleryPic2 from "../../images/gallery/pic2.jpg";
import galleryPic5 from "../../images/gallery/pic5.jpg";
import manipal from "../../images/shap/manipal.jpg";
import gas from "../../images/blog/grid/gas.jpg";
import gasbanner from "../../images/blog/default/gasbanner.jpg";


class BlogDetails extends Component{
	
	render(){
		return (
			<>
				
				<Header />
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>SYMPTOMS AND CAUSES OF GASTRITIS</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Blog Details</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1 bg-white">
						<div className="container">
							<div className="row">
								<div className="col-md-12 col-lg-7 col-xl-8 mb-30 mb-md-50">
									<div className="blog-card blog-single">
										<div className="post-media">
											<img src={gasbanner} alt=""/>
										</div>
										<div className="info-bx">
											<ul className="post-meta">
												<li className="author"><Link to="/blog-details"><img src={manipal} alt=""/>Dr. Manipal Puvvala</Link></li>
												<li className="date"><i className="far fa-calendar-alt"></i> 19 July 2021</li>
											</ul>
											<div className="ttr-post-title">
												<h2 className="post-title">SYMPTOMS AND CAUSES OF GASTRITIS</h2>
											</div>
											<div className="ttr-post-text">
												<p>Gastritis occurs when something damages or weakens the stomach lining (mucosa). Different things can trigger the problem, including:</p>
												<blockquote className="wp-block-quote">
													<p>Alcohol abuse: Chronic alcohol use can irritate and erode the stomach lining.</p>
                                                    <p>Autoimmune disease: In some people, the body’s immune system attacks healthy cells in the stomach lining.</p>
                                                    <p>Bacterial infection: H. pylori bacteria are the main cause of chronic gastritis and peptic ulcer disease (stomach ulcers). The bacteria break down the stomach’s protective lining and cause inflammation.</p>
                                                    <p>Bile reflux: The liver makes bile to help you digest fatty foods. “Reflux” means flowing back. Bile reflux occurs when bile flows back into the stomach instead of moving through the small intestine.</p>
                                                    <p>Medications: Steady use of nonsteroidal anti-inflammatory drugs (NSAIDs) or corticosteroids to manage chronic pain can irritate the stomach lining.</p>
                                                    <p>Physical stress: A sudden, severe illness or injury can bring on gastritis. Often, gastritis develops even after a trauma that doesn’t involve the stomach. Severe burns and brain injuries are two common causes.</p>
												</blockquote>
												<p>What are the symptoms of gastritis?</p>
                                                <p>Many people with gastritis don’t have symptoms. People who do have symptoms often mistake them for indigestion. Other signs of gastritis include:</p>
                                                <p>• Black, tarry stool.</p>
                                                <p>• Bloating.</p>
                                                <p>• Nausea and vomiting.</p>
                                                <p>• Feeling extra full during or after a meal.</p>
                                                <p>• Loss of appetite.</p>
                                                <p>• Stomach ulcers.</p>
                                                <p>• Losing weight without meaning to.</p>
                                                <p>• Upper abdominal (belly) pain or discomfort.</p>
                                                <p>• Vomiting blood.</p>

												<p>IS GASTRITIS CONTAGIOUS?</p>
												<p>Gastritis isn’t contagious, but the bacteria, H. pylori, can be contagious via the fecal-to-oral route. Good hand washing before handling of foods and proper sanitation (sewer and water systems) are the first line of defense against spread. Many people can develop gastritis after being infected with H. pylori bacteria.</p>
                                                <p>HOW IS GASTRITIS DIAGNOSED?</p>
                                                <p>Your healthcare provider will ask about your symptoms and history and perform a physical exam. Your provider may also order one or more of these tests:</p>
                                                <p>• Breath test: During an H. pylori breath test, you swallow a capsule or liquid containing urea, a harmless radioactive material. You then exhale into a balloon-like bag. H. pylori bacteria change urea into carbon dioxide. If you have the bacteria, the breath test will show an increase in carbon dioxide.</p>
                                                <p>• Blood test: A blood test checks for antibodies titers that fight H. pylori bacteria</p>
                                                <p>• Stool test: This test checks for H. pylori bacteria in your stool (poop).</p>
                                                <p>• Upper endoscopy: Your doctor uses an endoscope (a long thin tube with an attached camera) to see your stomach. The doctor inserts the scope through your esophagus, which connects your mouth to your stomach. An upper endoscopy procedure allows your provider to examine the stomach lining. You may also have a tissue sample (biopsy) taken from your stomach lining to test for infection.</p>
                                                <p>• Upper gastrointestinal (GI) exam: During an upper GI exam, you swallow a chalky substance called barium. The liquid coats your stomach lining, providing more detailed X-ray images.</p>
											</div>
											<div className="ttr-post-footer">
												<div className="post-tags">
													<strong>Tags:</strong>
                                                    <Link to="#">Gastritis</Link>
													<Link to="#">Health</Link> 
													<Link to="#">Growth</Link> 
													<Link to="#">Life</Link> 
												</div>
												<div className="share-post ml-auto">
													<ul className="social-media mb-0">
														<li><strong>Share:</strong></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.facebook.com/"><i className="fab fa-facebook-f"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.linkedin.com/"><i className="fab fa-linkedin-in"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									
									<AuthorProfile />
									
									
								</div>
								<div className="col-md-12 col-lg-5 col-xl-4 mb-30">
									<aside className="side-bar sticky-top aside-bx">
										
										<WidgetSearch />
										
										<WidgetRecentPosts />
										
										
										<WidgetTag />
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default BlogDetails;