import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// Layout
import Header from "../layout/header2";
import Footer from "../layout/footer";

// Elements
import AuthorProfile from "../elements/author-profile";
import CommentList from "../elements/comment-list";
import CommentRespond from "../elements/comment-respond";
import WidgetTag from "../elements/widget-tag";
import WidgetSearch from "../elements/widget-search";
import WidgetGallery from "../elements/widget-gallery";
import WidgetRecentPosts from "../elements/widget-recent-posts";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import blogDefaultPic1 from "../../images/blog/default/pic1.jpg";
import testPic3 from "../../images/testimonials/pic3.jpg";
import galleryPic2 from "../../images/gallery/pic2.jpg";
import galleryPic5 from "../../images/gallery/pic5.jpg";
import manipal from "../../images/shap/manipal.jpg";
import gas from "../../images/blog/grid/gas.jpg";
import varicosebanner from "../../images/blog/default/varicosebanner.jpg";


class BlogDetails extends Component{
	
	render(){
		return (
			<>
				
				<Header />
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>HOW TO TREAT, WHAT CAUSES VARICOSE VEINS?</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Blog Details</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1 bg-white">
						<div className="container">
							<div className="row">
								<div className="col-md-12 col-lg-7 col-xl-8 mb-30 mb-md-50">
									<div className="blog-card blog-single">
										<div className="post-media">
											<img src={varicosebanner} alt=""/>
										</div>
										<div className="info-bx">
											<ul className="post-meta">
												<li className="author"><Link to="/blog-details"><img src={manipal} alt=""/>Dr. Manipal Puvvala</Link></li>
												<li className="date"><i className="far fa-calendar-alt"></i>11 Sep 2022</li>
											</ul>
											<div className="ttr-post-title">
												<h2 className="post-title">HOW TO TREAT, WHAT CAUSES VARICOSE VEINS?</h2>
											</div>
											<div className="ttr-post-text">
												<p>Varicose veins are enlarged, swollen, twisting veins that often appear blue or dark purple in color.</p>
												<blockquote className="wp-block-quote">
													<p>They develop when faulty or damaged valves in the veins allow blood to flow in the wrong direction or to pool.</p>
                                                    <p>More than 23%Trusted Source of all adults are thought to be affected by varicose veins. Approximately 1 in 4 adults in the United States have varicose veins.</p>
												</blockquote>
												<p>TREATMENT</p>
                                                <p>If a person has no symptoms or discomfort and does not mind the sight of the varicose veins, treatment might not be necessary. Most people with varicose veins can get enough relief from home remedies, such as compression stockings.</p>
                                                <p>However, if a person has symptoms that do not improve easily, they may require medical treatment to reduce pain or discomfort or to address complications such as leg ulcers, skin discoloration, or swelling.</p>
                                                <p>Some individuals may also want treatment for cosmetic reasons, wanting to get rid of the “ugly” varicose veins.</p>
                                                <p>If varicose veins are large, they may need to be removed surgically. This is usually done under a general anesthetic. In most cases, the patient can go home the same day. However, if surgery is required on both legs, they may need to spend one night in the hospital.</p>
                                                <p>Laser treatments are often used to close off smaller veins and also spider veins. Strong bursts of light are applied to the vein, which gradually fades and disappears.</p>
                                                <p>Two incisions are made: one near the patient’s groin at the top of the target vein, and the other farther down the leg, either at the ankle or knee. The top of the vein is tied up and sealed. A thin, flexible wire is threaded through the bottom of the vein and then pulled out, taking the vein with it.</p>
                                                <p>This procedure does not usually require a hospital stay. Ligation and stripping can sometimes result in bruising, bleeding, and pain. On extremely rare occasions, there may be deep vein thrombosis (DVT), which is when a blood clot forms in the vein of the leg.</p>
                                                <p>CAUSES</p>
                                                <p>The veins have one-way valves so that the blood can travel in only one direction. If the walls of the vein become stretched and less flexible, or elastic, the valves may get weaker.</p>
                                                <p>A weakened valve can allow blood to leak backward and eventually flow in the opposite direction. When this occurs, blood can accumulate in a vein or veins, which then become enlarged and swollen.</p>

												<p>The veins farthest from the heart, such as those in the legs, are most often affected. This is because gravity makes it harder for the blood to flow back to the heart.</p>
												<p>Any condition that puts pressure on the abdomen has the potential to cause varicose veins. Examples include:</p>
                                                <p>•	pregnancy</p>
                                                <p>•	constipation</p>
                                                <p>•	tumors, in rare cases</p>
                                                <p>Another example is chronic venous insufficiency, which is when the skin does not exchange oxygen, nutrients, and waste products with the blood properly, because the blood flow is not strong enough.</p>
                                                <p>SCLEROTHERAPY</p>
                                                <p>A doctor injects a chemical into small- and medium-size varicose veins, which scars and closes them. A few weeks later, they should fade. A vein may need to be injected more than once.</p>
                                                <p>RADIOFREQUENCY ABLATION</p>
                                                <p>A doctor makes a small incision either above or below the knee with the help of an ultrasound scan. They then thread a narrow tube, or catheter, into the vein.</p>
                                                <p>The doctor then inserts a probe into the catheter, which emits radiofrequency energy. The radiofrequency energy heats up the vein, causing its walls to collapse, effectively closing it and sealing it shut. This procedure is preferred for larger varicose veins.</p>
                                                <p>Radiofrequency ablation is usually done with a local anesthetic.</p>
                                                <p>ENDOVENOUS LASER TREATMENT</p>
                                                <p>A doctor inserts a catheter into the patient’s vein. They then thread a small laser through the catheter and position it at the top of the target vein, which delivers short energy bursts that heat up the vein, sealing it shut.</p>
                                                <p>With the aid of an ultrasound scan, the doctor threads the laser all the way up the vein, gradually burning and sealing all of it. This procedure is done under local anesthetic. There may be some nerve injury, which is usually brief.</p>
											</div>
											<div className="ttr-post-footer">
												<div className="post-tags">
													<strong>Tags:</strong>
                                                    <Link to="#">Varicose Veins</Link>
													<Link to="#">Health</Link> 
													<Link to="#">Growth</Link> 
													<Link to="#">Life</Link> 
												</div>
												<div className="share-post ml-auto">
													<ul className="social-media mb-0">
														<li><strong>Share:</strong></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.facebook.com/"><i className="fab fa-facebook-f"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.linkedin.com/"><i className="fab fa-linkedin-in"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									
									<AuthorProfile />
									
									
								</div>
								<div className="col-md-12 col-lg-5 col-xl-4 mb-30">
									<aside className="side-bar sticky-top aside-bx">
										
										<WidgetSearch />
										
										<WidgetRecentPosts />
										
										
										<WidgetTag />
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default BlogDetails;