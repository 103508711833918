import React, { Component } from 'react';

class LatestNewsSection extends Component{
	render(){
		
		return(
			<>
				
				<section className="section-sp1 service-wraper2">
					<div className="container">
						<div className="row">
							<div className="col-xl-3 col-sm-6 mb-30">
								<div className="feature-container feature-bx3">
									<h2 className="counter text-secondary">10</h2>
									<h5 className="ttr-title">Years With You</h5>
									<p>I have been a Senior Consultant Surgeon for over 10 years. Prior to commencing my specialist training, I was a rural GP in Hyderabad.</p>
								</div>
							</div>
							<div className="col-xl-3 col-sm-6 mb-30">
								<div className="feature-container feature-bx3">
									<h2 className="counter text-secondary">22</h2>
									<h5 className="ttr-title">Awards</h5>
									<p>As a surgeon I believe that it is essential to address surgical problems in accordance with world’s best practice.</p>
								</div>
							</div>
							<div className="col-xl-3 col-sm-6 mb-30">
								<div className="feature-container feature-bx3">
									<h2 className="counter text-secondary">1</h2>
									<h5 className="ttr-title">Doctor at your desk</h5>
									<p>Our surgeon and our team will be here to assist you with kindness and support.</p>
								</div>
							</div>
							<div className="col-xl-3 col-sm-6 mb-30">
								<div className="feature-container feature-bx3">
									<h2 className="counter text-secondary">100+</h2>
									<h5 className="ttr-title">Satisfied Patients</h5>
									<p>If surgery is required, our role encompasses building a reassuring environment to prepare your family for the surgery, and of course to provide expert surgical care and follow-up.</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				
			</>
		);
	}
}

export default LatestNewsSection;