import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// Layout
import Header from "../layout/header2";
import Footer from "../layout/footer";

// Elements
import AuthorProfile from "../elements/author-profile";
import CommentList from "../elements/comment-list";
import CommentRespond from "../elements/comment-respond";
import WidgetTag from "../elements/widget-tag";
import WidgetSearch from "../elements/widget-search";
import WidgetGallery from "../elements/widget-gallery";
import WidgetRecentPosts from "../elements/widget-recent-posts";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import blogDefaultPic1 from "../../images/blog/default/pic1.jpg";
import testPic3 from "../../images/testimonials/pic3.jpg";
import galleryPic2 from "../../images/gallery/pic2.jpg";
import galleryPic5 from "../../images/gallery/pic5.jpg";
import manipal from "../../images/shap/manipal.jpg";
import gas from "../../images/blog/grid/gas.jpg";
import thyroidbanner from "../../images/blog/default/thyroidbanner.jpg";


class BlogDetails extends Component{
	
	render(){
		return (
			<>
				
				<Header />
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>WHAT CAUSES THYROID DISEASE?</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Blog Details</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1 bg-white">
						<div className="container">
							<div className="row">
								<div className="col-md-12 col-lg-7 col-xl-8 mb-30 mb-md-50">
									<div className="blog-card blog-single">
										<div className="post-media">
											<img src={thyroidbanner} alt=""/>
										</div>
										<div className="info-bx">
											<ul className="post-meta">
												<li className="author"><Link to="/blog-details"><img src={manipal} alt=""/>Dr. Manipal Puvvala</Link></li>
												<li className="date"><i className="far fa-calendar-alt"></i>18 Aug 2022</li>
											</ul>
											<div className="ttr-post-title">
												<h2 className="post-title">WHAT CAUSES THYROID DISEASE?</h2>
											</div>
											<div className="ttr-post-text">
												<p>Your thyroid creates and produces hormones that play a role in many different systems throughout your body. When your thyroid makes either too much or too little of these important hormones, it’s called a thyroid disease.</p>
												<blockquote className="wp-block-quote">
													<p>The two main types of thyroid disease are hypothyroidism and hyperthyroidism. Both conditions can be caused by other diseases that impact the way the thyroid gland works.</p>
                                                    
												</blockquote>
												<p>Conditions that can cause hypothyroidism include:</p>
                                                <p>• Thyroiditis: This condition is an inflammation (swelling) of the thyroid gland. Thyroiditis can lower the amount of hormones your thyroid produces</p>
                                                <p>• Hashimoto’s thyroiditis: A painless disease, Hashimoto’s thyroiditis is an autoimmune condition where the body’s cells attack and damage the thyroid. This is an inherited condition</p>
                                                <p>• Postpartum thyroiditis: This condition occurs in 5% to 9% of women after childbirth. It’s usually a temporary condition</p>
                                                <p>• Iodine deficiency: Iodine is used by the thyroid to produce hormones. An iodine deficiency is an issue that affects several million people around the world</p>
                                                <p>• A non-functioning thyroid gland: Sometimes, the thyroid gland doesn’t work correctly from birth. This affects about 1 in 4,000 newborns. If left untreated, the child could have both physical and mental issues in the future. All newborns are given a screening blood test in the hospital to check their thyroid function</p>
                                                <p>Conditions that can cause hyperthyroidism include:</p>
                                                <p>• Graves’ disease: In this condition the entire thyroid gland might be overactive and produce too much hormone. This problem is also called diffuse toxic goiter (enlarged thyroid gland)</p>
                                                <p>• Nodules: Hyperthyroidism can be caused by nodules that are overactive within the thyroid. A single nodule is called toxic autonomously functioning thyroid nodule, while a gland with several nodules is called a toxic multi-nodular goiter</p>
                                                <p>• Thyroiditis: This disorder can be either painful or not felt at all. In thyroiditis, the thyroid releases hormones that were stored there. This can last for a few weeks or months</p>
                                                <p>• Excessive iodine: When you have too much iodine (the mineral that is used to make thyroid hormones) in your body, the thyroid makes more thyroid hormones than it needs. Excessive iodine can be found in some medications (amiodarone, a heart medication) and cough syrups</p>

												<p>Is there a higher risk of developing thyroid disease if I have diabetes?</p>
												<p>If you have diabetes, you’re at a higher risk of developing a thyroid disease than people without diabetes. Type 1 diabetes is an autoimmune disorder. If you already have one autoimmune disorder, you are more likely to develop another one.</p>
                                                <p>For people with type 2 diabetes, the risk is lower, but still there. If you have type 2 diabetes, you’re more likely to develop a thyroid disease later in life.</p>
                                                <p>Regular testing is recommended to check for thyroid issues. Those with type 1 diabetes may be tested more often — immediately after diagnosis and then every year or so — than people with type 2 diabetes. There isn’t a regular schedule for testing if you have type 2 diabetes, however your healthcare provider may suggest a schedule for testing over time.</p>
                                                <p>If you have diabetes and get a positive thyroid test, there are a few things to you can do to help feel the best possible. These tips include:</p>
                                                <p>• Getting enough sleep</p>
                                                <p>• Exercising regularly</p>
                                                <p>• Watching your diet</p>
                                                <p>• Taking all of your medications as directed</p>
												<p>• Getting tested regularly as directed by your healthcare provider</p>
												<p>What common symptoms can happen with thyroid disease?</p>
												<p>There are a variety of symptoms you could experience if you have a thyroid disease. Unfortunately, symptoms of a thyroid condition are often very similar to the signs of other medical conditions and stages of life. This can make it difficult to know if your symptoms are related to a thyroid issue or something else entirely.</p>
												<p>For the most part, the symptoms of thyroid disease can be divided into two groups — those related to having too much thyroid hormone (hyperthyroidism) and those related to having too little thyroid hormone (hypothyroidism).</p>
												<p>Symptoms of an overactive thyroid (hyperthyroidism) can include:</p>
												<p>• Experiencing anxiety, irritability and nervousness</p>
												<p>• Having trouble sleeping</p>
												<p>• Losing weight</p>
												<p>• Having an enlarged thyroid gland or a goiter</p>
												<p>• Having muscle weakness and tremors</p>
												<p>• Experiencing irregular menstrual periods or having your menstrual cycle stop</p>
												<p>• Feeling sensitive to heat</p>
												<p>• Having vision problems or eye irritation</p>
												<p>Symptoms of an underactive thyroid (hypothyroidism) can include:</p>
												<p>• Feeling tired (fatigue)</p>
												<p>• Gaining weight</p>
												<p>• Experiencing forgetfulness</p>
												<p>• Having frequent and heavy menstrual periods</p>
												<p>• Having dry and coarse hair</p>
												<p>• Having a hoarse voice</p>
												<p>• Experiencing an intolerance to cold temperatures</p>
												
											</div>
											<div className="ttr-post-footer">
												<div className="post-tags">
													<strong>Tags:</strong>
                                                    <Link to="#">Thyroid</Link>
													<Link to="#">Health</Link> 
													<Link to="#">Growth</Link> 
													<Link to="#">Life</Link> 
												</div>
												<div className="share-post ml-auto">
													<ul className="social-media mb-0">
														<li><strong>Share:</strong></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.facebook.com/"><i className="fab fa-facebook-f"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.linkedin.com/"><i className="fab fa-linkedin-in"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									
									<AuthorProfile />
									
									
								</div>
								<div className="col-md-12 col-lg-5 col-xl-4 mb-30">
									<aside className="side-bar sticky-top aside-bx">
										
										<WidgetSearch />
										
										<WidgetRecentPosts />
										
										
										<WidgetTag />
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default BlogDetails;