import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// Layout
import Header from "../layout/header2";
import Footer from "../layout/footer";

// Elements
import AuthorProfile from "../elements/author-profile";
import CommentList from "../elements/comment-list";
import CommentRespond from "../elements/comment-respond";
import WidgetTag from "../elements/widget-tag";
import WidgetSearch from "../elements/widget-search";
import WidgetGallery from "../elements/widget-gallery";
import WidgetRecentPosts from "../elements/widget-recent-posts";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import blogDefaultPic1 from "../../images/blog/default/pic1.jpg";
import testPic3 from "../../images/testimonials/pic3.jpg";
import galleryPic2 from "../../images/gallery/pic2.jpg";
import galleryPic5 from "../../images/gallery/pic5.jpg";
import manipal from "../../images/shap/manipal.jpg";
import gas from "../../images/blog/grid/gas.jpg";
import colonbanner from "../../images/blog/default/colonbanner.jpg";


class BlogDetails extends Component{
	
	render(){
		return (
			<>
				
				<Header />
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>COLON AND RECTAL DISORDERS</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Blog Details</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1 bg-white">
						<div className="container">
							<div className="row">
								<div className="col-md-12 col-lg-7 col-xl-8 mb-30 mb-md-50">
									<div className="blog-card blog-single">
										<div className="post-media">
											<img src={colonbanner} alt=""/>
										</div>
										<div className="info-bx">
											<ul className="post-meta">
												<li className="author"><Link to="/blog-details"><img src={manipal} alt=""/>Dr. Manipal Puvvala</Link></li>
												<li className="date"><i className="far fa-calendar-alt"></i> 19 July 2021</li>
											</ul>
											<div className="ttr-post-title">
												<h2 className="post-title">COLON AND RECTAL DISORDERS</h2>
											</div>
											<div className="ttr-post-text">
												<p>Colonic diverticula are little out-pouchings or sacs in the bowel lining that occur when the lining gets pushed through weak spots in the muscle of the bowel wall. They usually appear in the sigmoid colon, where the large bowel exerts the highest pressure.</p>
												<blockquote className="wp-block-quote">
													<p>Colonic diverticula are small out-pouchings or sacs that push through weak spots in the muscle layers of the colon wall.</p>
                                                    <p>Diverticular disease is very common in Western societies and may be due to low-fiber Western diets. Diverticula rarely cause symptoms unless one of the sacs gets blocked and infected. This is called diverticulitis and occurs in about 10% of people with diverticula. There is occasionally bleeding in this area.</p>
                                                    <p>About half the patients who have complications of their diverticula will need surgery.</p>
                                                    
												</blockquote>
												<p>POLYPS AND CANCER</p>
                                                <p>Cancer of the colon and rectum is a major health problem in America today. It occurs when the cells in the lining of the large bowel grow and divide in an uncontrolled manner. Many factors contribute to this loss of control, including the environment, our diet, and genetics (what we inherit from our parents).</p>
                                                <p>The first abnormality in the bowel in colon cancer is a polyp, a small growth that may look like a mushroom protruding from the lining tissue of the large bowel. There are many types of polyps, and not all are the type that turn into cancer. However, removing these polyps before they develop severe changes and grow can prevent the progression to cancer.</p>
                                                <p>Polyps are small abnormal growths that protrude from the tissue lining the colon or rectum.</p>
                                                <p>When cancer develops, it must be removed by surgery. Chemotherapy may be recommended for cancer of the colon or rectum. Certain cancers of the rectum may require radiation treatment.</p>
                                                <p>With prompt, expert treatment, most people can be cured of colorectal cancer. Although people may worry about having to wear a colostomy bag on the abdomen to collect stool, very few people need a permanent colostomy bag.</p>
                                                <p>Because colorectal cancer comes from polyps, a colonoscopy procedure can prevent colorectal cancer by finding and removing polyps. People at greater risk for colorectal cancer include those who have had polyps or cancers in the past, or those who have a family history of colorectal cancer.</p>
                                                <p>COLITIS</p>
                                                <p>Colitis is a group of conditions that cause inflammation of the large bowel.</p>
                                                <p>Colitis is an inflammation of the inner lining of the colon.</p>
                                                <p>There are several types of colitis, including:</p>

												<p>• Infectious colitis (due to an infection that attacks the large bowel)</p>
												<p>• Ischemic colitis (caused by not enough blood going to the colon)</p>
                                                <p>• Radiation colitis (after radiation therapy, usually for prostate, rectal, or gynecological cancer)</p>
                                                <p>• Ulcerative colitis</p>
                                                <p>• Crohn's disease</p>
                                                <p>Colitis causes diarrhea, rectal bleeding, abdominal cramps, and urgency (increased need to go to the bathroom). Treatment depends on the diagnosis, which is made by colonoscopy and biopsy (removal of cells or tissue for examination under a microscope).</p>
                                                <p>SUMMARY</p>
                                                <p>Many diseases of the colon and rectum can be prevented or treated by seeking prompt medical care. People who have symptoms of any of these conditions should consult their doctor.</p>
                                                <p>Most importantly, colon cancer is a preventable disease. The most important risk factor is having a direct family member who had colon cancer. Ask your doctor when you need an evaluation (usually a colonoscopy) to look for polyps. For people with no family history and no symptoms, the current recommendation is that everyone should have their first colonoscopy at age 45.</p>
											</div>
											<div className="ttr-post-footer">
												<div className="post-tags">
													<strong>Tags:</strong>
                                                    <Link to="#">Colo-Rectal Disorders</Link>
													<Link to="#">Health</Link> 
													<Link to="#">Growth</Link> 
													<Link to="#">Life</Link> 
												</div>
												<div className="share-post ml-auto">
													<ul className="social-media mb-0">
														<li><strong>Share:</strong></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.facebook.com/"><i className="fab fa-facebook-f"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.linkedin.com/"><i className="fab fa-linkedin-in"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									
									<AuthorProfile />
									
									
								</div>
								<div className="col-md-12 col-lg-5 col-xl-4 mb-30">
									<aside className="side-bar sticky-top aside-bx">
										
										<WidgetSearch />
										
										<WidgetRecentPosts />
										
										
										<WidgetTag />
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default BlogDetails;