import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Import Images
import thyroid from "../../images/blog/grid/thyroid.jpg"
import varicose from "../../images/blog/grid/varicose.jpg"
import foot from "../../images/blog/grid/foot.jpg"


class WidgetRecentPosts extends Component{
	render(){
		return(
			<>
				<div className="widget recent-posts-entry">
					<h4 className="widget-title">Recent Posts</h4>
					<div className="widget-post-bx">
						<div className="widget-post clearfix">
							<div className="ttr-post-media"> <img src={foot} width="200" height="143" alt=""/> </div>
							<div className="ttr-post-info">
								<div className="ttr-post-header">
									<h6 className="post-title"><Link to="/blog-diabetic">DIABETIC FOOT PROBLEMS.</Link></h6>
								</div>
								<ul className="post-meta">
									<li className="date"><i className="far fa-calendar-alt"></i>10 Sep 2022</li>
								</ul>
							</div>
						</div>
						<div className="widget-post clearfix">
							<div className="ttr-post-media"> <img src={varicose} width="200" height="160" alt=""/> </div>
							<div className="ttr-post-info">
								<div className="ttr-post-header">
									<h6 className="post-title"><Link to="/blog-varicose">HOW TO TREAT, WHAT CAUSES VARICOSE VEINS?</Link></h6>
								</div>
								<ul className="post-meta">
									<li className="date"><i className="far fa-calendar-alt"></i>11 Sep 2022</li>
								</ul>
							</div>
						</div>
						<div className="widget-post clearfix">
							<div className="ttr-post-media"> <img src={thyroid} width="200" height="160" alt=""/> </div>
							<div className="ttr-post-info">
								<div className="ttr-post-header">
									<h6 className="post-title"><Link to="/blog-thyroid">WHAT CAUSES THYROID DISEASE?</Link></h6>
								</div>
								<ul className="post-meta">
									<li className="date"><i className="far fa-calendar-alt"></i>18 Aug 2022</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				
			</>
		);
	}
}

export default WidgetRecentPosts;